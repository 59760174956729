import FooterFour from 'common/footer/FooterFour';
import React, { useEffect, useState } from 'react';
import { FiUser, FiCalendar } from "react-icons/fi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';


function BlogDetailsContent({ id }) {
    const [newData, setData] = React.useState({
        id: 0,
        title: "",
        body: "",
        date: "",
        image: "",
        // diğer alanları da ekleyin
      });
    
      React.useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`https://excel.progressytems.com/api/v1/apx/getDetail?origin=astwrlwde&id=${id}&additionalParam=haberler`);
            const data = await response.json();
    
            if (!response.ok) {
              throw new Error(data.error || 'Fetch failed');
            }
    
              setData(prevState => ({
                ...prevState,
                id: data[0].id,
                title:data[0].title,
                body:data[0].body,
                date:data[0].date,
                image: data[0].image,
    
              }));
       
    
    
          } catch (error) {
          } finally {
          }
        };
    
        fetchData();
      }, [id]);

  return (
    <>
    <div className='dribba'>

      <div className="post-page-banner" >
        <div className="container">
          <div className="row  gapbeka" >
            <div className="col-lg-8 offset-lg-2" style={{width:"100%",position:"relative"}}>
            <Link to="/news">
            <div className='fikar'><FaArrowLeftLong /></div>
          </Link>
         
              <div className="content text-center">

                <div className="page-title">

                  <h1  className="newakefont vitangri">{newData.title}</h1>
                </div>
           
                <div className="thumbnaill trywokr" style={{position:'relative'}}>
                <ul className="rnn-meta-list newakefont">
                <li className='newakefont' style={{color:"rgba(255,255,255,0.5)",fontSize:"14px"}}><FiCalendar /><span  style={{color:"rgba(255,255,255,0.5)",marginTop:"5px",marginLeft:"5px",fontWeight:"600",}}>{newData.date}</span></li>
              </ul>
                  <img style={{borderRadius:"0px",}} className="w-100 radius imgcolor" src={`${process.env.PUBLIC_URL}/${newData.image}` } alt="Blog Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blogcontor pt--60 rn-section-gapBottom infinite-scroll">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
            <div className="content newakefont" dangerouslySetInnerHTML={{ __html: newData.body}} />

            </div>
          </div>
        </div>
      </div>
      </div>

    </>
  )
}

export default BlogDetailsContent;
